<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量
</style>

<template>
  <!-- 岗位管理 -->
  <section class="page-container">
    <div class="table-box">
      <!-- 搜索表单 -->
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="岗位名称：">
          <el-input v-model="searchForm.name" placeholder="岗位名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch" icon="el-icon-search">
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 数据内容 -->
      <div class="table-container" id="tableContainer">
        <!-- 内页工具栏 -->
        <div class="tool-bar">
          <div class="tool-left">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="formDialogVisible = true"
            >
              添加
            </el-button>
            <el-button
              size="small"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete()"
            >
              删除
            </el-button>
          </div>
          <div class="tool-right">
            <el-tooltip effect="dark" content="刷新">
              <el-button
                size="small"
                icon="el-icon-refresh-right"
                @click="handleRefresh"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="全屏">
              <el-button size="small" @click="handleFullScreen">
                <span class="iconfont">
                  {{ (isFull && "&#xe641;") || "&#xe8fa;" }}
                </span>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="table-data" ref="tableContainer">
          <!-- 内页表格数据 -->
          <el-table
            ref="tableBox"
            border
            style="width: 100%"
            :max-height="tableHeight"
            :data="tableData"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
              fixed="left"
            />
            <el-table-column prop="id" label="ID" width="80" align="center" />
            <el-table-column
              prop="name"
              label="岗位名称"
              min-width="180"
              align="center"
            />
            <el-table-column
              prop="sort"
              label="排序"
              min-width="120"
              align="center"
            />
            <el-table-column label="创建时间" min-width="210" align="center">
              <template slot-scope="scope">
                <p>
                  {{
                    (scope.row.creatTime * 1000)
                      | formatTime("YYYY-MM-DD HH:mm:ss")
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="160"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  class="color-primary"
                  type="text"
                  icon="el-icon-edit"
                  @click="getDetail(scope.row.id)"
                >
                  编辑
                </el-button>
                <el-button
                  class="color-danger"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row.id)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页控件 -->
        <el-pagination
          background
          layout="total,sizes,prev, pager, next,jumper"
          :current-page="pagination.current"
          :page-size="pagination.size"
          :page-sizes="pagination.sizes"
          :total="pagination.total"
          @size-change="handleSize"
          @current-change="handleCurrent"
        >
        </el-pagination>
      </div>
      <!-- 新增/编辑表单弹窗 -->
      <el-dialog
        :title="(id && '修改岗位') || '添加岗位'"
        :visible.sync="formDialogVisible"
        width="420px"
      >
        <el-form
          :model="formData"
          :rules="rules"
          ref="formData"
          label-width="84px"
        >
          <el-form-item label="岗位名称:" prop="name">
            <el-input v-model="formData.name" placeholder="请输入岗位名称" />
          </el-form-item>
          <el-form-item label="排序:" prop="sort">
            <el-input-number v-model="formData.sort" :min="1" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleCancle">取 消</el-button>
          <el-button type="primary" @click="handleSubmit"> 确 定 </el-button>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
export default {
  name: "station",
  inject: ["reload"],
  data() {
    return {
      tableHeight: 0, //表格最大高度
      searchForm: {}, //搜索表单
      isFull: false, //全屏开启
      tableData: [], //表格数据
      multipleSelection: [], //表格多选储存数据
      pagination: {
        page: 1,
        total: 100,
        current: 1,
        size: 10,
        sizes: [10, 15, 20, 25, 30],
      }, //分页数据
      id: null, //修改用户id
      imageUrl: "", //图片上传成功回显链接
      formDialogVisible: false, //修改/添加表单弹窗显示
      formData: {
        sort: 1,
      }, //表单存储数据
      rules: {
        name: [{ required: true, message: "请输入岗位名称", trigger: "blur" }],
        sort: [
          { required: true, message: "请选择岗位排序", trigger: "change" },
        ],
      }, //表单校验规则
    };
  },
  created() {
    // 获取初始化数据
    this.getData();
    /**
     * 页面dom元素加载完后调用，计算表格最大高度
     */
    this.$nextTick(function () {
      this.initTableHeight();
    });
  },
  mounted() {
    window.addEventListener("resize", () => {
      // 窗口尺寸改变时触发事件
      if (!this.$screenfull.isFullscreen) {
        this.isFull = false;
      }
      this.initTableHeight();
    });
  },
  methods: {
    /** 获取表格数据 */
    getData() {
      // 模拟数据
      let data = [
        {
          id: 1,
          name: "产品经理",
          sort: 1,
          creatTime: 1648792694,
        },
        {
          id: 2,
          name: "技术经理",
          sort: 1,
          creatTime: 1648792694,
        },
      ];
      /**
       * 请求接口数据
       */
      this.tableData = data;
      this.$forceUpdate();
    },
    /** 分页参数-每页显示条数改变 */
    handleSize(val) {
      this.pagination = {
        ...this.pagination,
        size: val,
      };
      this.getData();
    },
    /** 分页参数-当前页改变 */
    handleCurrent(val) {
      this.pagination = {
        ...this.pagination,
        page: val,
      };
      this.getData();
    },
    /** 查询 */
    handleSearch() {
      this.pagination = {
        ...this.pagination,
        page: 1,
      };
      this.getData();
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 初始化表格高度 */
    initTableHeight() {
      if (this.$refs["tableContainer"]) {
        this.tableHeight = this.$refs["tableContainer"].offsetHeight;
      }
    },
    /** 全屏 */
    handleFullScreen() {
      const element = document.getElementById("tableContainer");
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request(element);
      } else {
        this.$screenfull.exit(element);
      }
    },
    /** 表格多选触发 */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /** 修改前获取详情信息 */
    getDetail(id) {
      this.id = id;
      /**
       * 请求接口获取详情数据
       */

      //请求返回成功后打开弹窗
      this.formDialogVisible = true;
    },
    /** 取消表单编辑 */
    handleCancle() {
      this.formDialogVisible = false;
      this.formData = {
        sort: 1,
      };
      this.$refs["formData"].clearValidate();
    },
    /** 提交表单信息*/
    handleSubmit() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          /**
           * 请求接口，修改密码
           */
          if (this.id) {
            // 存在id，执行编辑请求
          } else {
            // 不存在id，执行添加请求
          }
          // 请求后，不论是否成功，调用方法关闭弹窗
          this.handleCancle();

          // 请求成功后，调用方法，更新页面数据
          this.getData();
        }
      });
    },
    /** 删除岗位 */
    handleDelete(id) {
      /** 判断id是否存在，存在即单个删除。反之触发批量删除 */
      if (!id && id != 0) {
        if (this.multipleSelection.length > 0) {
          id = this.multipleSelection.map((item) => item.id);
        }
      }

      if (id || id == 0) {
        id = (id instanceof Array && id.join(",")) || id;

        /** 弹窗再次确认操作 */
        this.$confirm("此操作将永久删除该岗位, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          /** 确认触发 */
          .then(() => {
            /**
             * 请求接口删除数据
             */
            // this.$refs['tableBox'].clearSelection(); //删除操作请求失败，清空表格多选

            /** 判断当前页数据是否仅剩1条 */
            if (this.tableData.length == 1) {
              this.pagination = {
                ...this.pagination,
                page:
                  (this.pagination.page > 1 && this.pagination.page - 1) || 1, // 判断当前页是否为第一页，不为第一页则减少一页
              };
            }
            this.$message({
              type: "success",
              message: "岗位已删除!",
            });
            this.getData();
          })
          /** 取消触发 */
          .catch(() => {
            this.$refs["tableBox"].clearSelection(); //删除操作取消，清空表格多选
            this.$message({
              type: "info",
              message: "已取消删除操作",
            });
          });
      }
    },
  },
};
</script>